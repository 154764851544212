<template>
  <span class="ws-button-wrapper">
    <q-btn
      ref="qBtn"
      unelevated
      v-bind="$attrs"
      class="ws-button"
      :flat="flat"
      :rounded="rounded"
      :class="{
        primary,
        secondary,
        ghost,
        light,
        small,
        smaller,
        dark,
        ['ws-typo-button-desktop']: forcedDesktop,
        ['is-loading']: $attrs.loading,
        ['use-paddings']: usePaddings,
        ['icon-only']: iconOnly,
        ['icon-right']: iconRight,
      }"
      :ripple="!themeCary"
    >
      <!-- credit: https://stackoverflow.com/a/50892881/861615 -->
      <template
        v-for="(_, slot) of slots"
        v-slot:[slot]="scope"
      >
        <slot
          :name="slot"
          v-bind="scope"
        ></slot>
      </template>

      <ws-icon
        v-if="icon && !iconRight"
        class="ws-button-icon"
        :class="`ws-button-icon--${icon}`"
        :name="icon"
        :size="iconSizeCalc"
      />

      <!-- BEWARE $slots.default is not reactive, cannot be moved to computed values -->
      <div
        v-if="$slots.default"
        class="slot-content"
        :class="{
          'q-pl-sm': icon && $slots.default && !iconRight,
          'q-pr-sm': icon && $slots.default && iconRight,
        }"
      >
        <slot></slot>
      </div>

      <ws-icon
        v-if="icon && iconRight"
        class="ws-button-icon"
        :class="`ws-button-icon--${icon}`"
        :name="icon"
        :size="iconSizeCalc"
      />

      <template v-if="useThemeSpinner" v-slot:loading>
        <ws-cary-spinner :color="loaderColorResolved" />
      </template>
    </q-btn>
    <q-tooltip
      v-if="tooltip"
      :content-class="tooltipClass"
      :anchor="tooltipAnchor"
      :self="tooltipSelf"
      :delay="200"
    >
      {{ tooltip }}
    </q-tooltip>
  </span>
</template>

<script lang="ts">
export default {
  inheritAttrs: false
};
</script>

<script lang="ts" setup>
import { QBtn, QTooltip } from 'quasar';
import type { QTooltipProps } from 'quasar';
import { computed, ref } from '@vue/runtime-core';
import { getCurrentInstance, useSlots } from 'vue';
import type { PropType } from 'vue';
import config from '@/tmp_uikit/services/config.service';
import WsIcon from '@/tmp_uikit/component-3/WsIcon.vue';
// @ts-ignore
import {WsCarySpinner} from '@odin/components-vue3';

const props = defineProps({
  //  Styles
  primary: { type: Boolean },
  secondary: { type: Boolean },
  ghost: { type: Boolean },
  light: { type: Boolean },
  flat: { type: Boolean },
  small: { type: Boolean },
  smaller: { type: Boolean },
  rounded: { type: Boolean },
  forcedDesktop: { type: Boolean }, // forced desktop font-size/line-height for every screen size
  dark: { type: Boolean }, // dark mode
  usePaddings: { type: Boolean }, // force to use paddings for button text if needed
  icon: { type: String, default: null },
  loaderColor: { type: String, default: 'black' },
  iconRight: { type: Boolean, default: false }, // icon on right side of label
  iconSize: { type: Number, default: null },
  //  Tooltip
  tooltip: { type: String, required: false, default: null },
  tooltipAnchor: { type: String as PropType<QTooltipProps['anchor']>, default: undefined },
  tooltipSelf: { type: String as PropType<QTooltipProps['self']>, default: undefined },
  tooltipClass: { type: String, default: '' },
});

const themeCary = true;
const desktop = ref(false);
const tablet = ref(false);
const mobile = ref(false);
const $slots = useSlots();

const loaderColorResolved = computed(() => {
  if (props.loaderColor) {
    return props.loaderColor;
  }
  if (props.primary) {
    return 'dark';
  }
  if (props.secondary) {
    return 'white';
  }
  return undefined; // default
});

const useThemeSpinner = computed(() => config.themeOptions?.spinner);

const slots = computed(() => {
  const instance = getCurrentInstance();

  const namedSlots = { ...instance?.slots };
  // all but default, because default is handled differently
  delete namedSlots.default;
  if (config.themeOptions?.spinner) {
    delete namedSlots.loading;
  }
  return namedSlots;
});

const iconSizeCalc = computed(() => {
  // CAUTION! icon height affects button height!
  const defaultSize = config.themeOptions?.iconSize
      || (config.themeOptions?.btnDynamicIconSize
        && ((desktop && 24)
          || (tablet && 20)
          || (mobile && 16.8)))
      || 22;
  return props.iconSize || (props.flat && props.small ? 14 : defaultSize);
});

const iconOnly = computed(() => !$slots.default);
</script>

<style lang="scss" scoped>
span.ws-button-wrapper {
  display: inline-block;
}

button.q-btn.ws-button,
a.q-btn.ws-button {
  text-transform: none;

  ::v-deep(.q-btn__content) {
    flex-wrap: nowrap;
  }

  .ws-cary-spinner {
    max-width: 70%;
    max-height: 70%;
  }

  &:not([class*=' text-']) {
    // default color
    color: $button-primary-text;
  }

  &.primary,
  &.secondary {
    border: 0;
  }

  &.light:before {
    background-color: $ws-white;
  }

  &.primary:before {
    background-color: $button-primary-bg;
  }

  &.secondary:before {
    background-color: $ws-secondary;
  }

  &.ghost {
    &:before {
      background-color: transparent;
    }

    // z-index: 2;
    &.dark {
      color: $ws-white;

      &:after {
        border-color: $ws-white;
      }
    }

    &:after {
      // z-index: 1;
      background-color: transparent;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: inherit;
      border: 1px solid $ws-base130;
    }
  }

  &.use-paddings {
    padding-left: map-get($space-xs, x) !important;
    padding-right: map-get($space-xs, x) !important;
  }

  .slot-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
