<template>
  <div class="ws-password-strength" :class="{ valid: isPasswordValid }">
    <ul v-for="(chnk, index) in chunks" :key="index" class="q-pa-none">
      <li
        v-for="(rule, idx) in chnk"
        :key="idx"
        :class="{
          satisfied: isRuleValid(getRuleId(rule)),
          error: showError && !isRuleValid(getRuleId(rule)),
        }"
      >
        <small>
          <ws-icon
            class="q-mr-sm"
            :name="
              isRuleValid(getRuleId(rule)) ? 'success' : showError ? 'error' : 'minus'
            "
          />
        </small>
        <small>{{ $t(getRuleLabel(rule)) }}</small>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { mapBy } from '@odin/utils';
import { chunk, get } from 'lodash-es';
import { PasswordStrengthValidator } from './PasswordStrengthValidator';
import { PASSWORD_STRENGTH_RULES } from './PasswordStrengthValidator.enum';
import WsIcon from './WsIcon.vue';

interface Rule {
  id: string;
  label: string;
}

const RULE_CASE = 'case';

const emit = defineEmits(['valid']);

const props = defineProps({
  password: { type: String, default: '' },
  showError: { type: Boolean },
});

const rules: Rule[] = [
  {
    id: PASSWORD_STRENGTH_RULES.RULE_MIN,
    label: 'wsk.ws_password_strength.rule1',
  },
  {
    id: RULE_CASE,
    label: 'wsk.ws_password_strength.rule2',
  },
  {
    id: PASSWORD_STRENGTH_RULES.RULE_DIGIT,
    label: 'wsk.ws_password_strength.rule3',
  },
  {
    id: PASSWORD_STRENGTH_RULES.RULE_SPEC,
    label: 'wsk.ws_password_strength.rule4',
  },
];

const passValidator: PasswordStrengthValidator = new PasswordStrengthValidator();

const passValidity: any = computed(() => mapBy('rule', passValidator.validate(props.password)));

function chunks() : Rule[][] {
  return chunk(rules, 2);
}

const isPasswordValid = computed(() => {
  //@ts-ignore
  const valid = !Object.values(passValidity).some(
    (rule: any) => rule.result,
  );
  emit('valid', valid);
  return valid;
});

function isRuleValid(ruleId: string) {
  if (ruleId === RULE_CASE) {
    return !(
      passValidity[PASSWORD_STRENGTH_RULES.RULE_UP_CASE]?.result
        || passValidity[PASSWORD_STRENGTH_RULES.RULE_LOW_CASE]?.result
    );
  }
  return !passValidity[ruleId]?.result;
}

const getRuleId = (rule: any) => {
  return get(rule, 'id');
};

const getRuleLabel = (rule: any) => {
  return get(rule, 'label');
};
</script>

<style lang="scss" scoped>
.ws-password-strength {
  @media (min-width: $breakpoint-sm) {
    display: grid;
    grid-template-areas: 'left right';
    justify-content: start;
    align-items: start;
    column-gap: map-get($space-2xl, x);
  }

  @media (min-width: $ws-breakpoint-tablet-min) {
    column-gap: map-get($space-4xl, x);
  }

  ul {
    list-style: none;
    // override host app interference
    margin-bottom: 0;

    &:first-of-type {
      grid-area: left;
    }

    &:last-of-type {
      grid-area: right;
    }

    li {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: start;
      align-items: start;

      > small > .ws-icon {
        vertical-align: text-bottom;
      }
    }
  }
}
.satisfied {
  color: $ws-success;
}
.error {
  color: $ws-red;
}
</style>
