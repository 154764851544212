import { createApp } from 'vue';
import { Quasar } from 'quasar';
import iconSet from 'quasar/icon-set/svg-material-icons';
import i18n, { init } from './i18n';
import App from './App.vue';
import './styles/main.scss';
import { setupSentry } from '@/plugins/sentry/sentry';
import { setupWsWrapper } from '@/tmp_uikit/component-3/helpers/WsWrapper';
import { getConfig } from '@/services/config';
import { checkRedirect } from '@/services/redirect';
import * as Sentry from '@sentry/browser';
import { setTheme } from '@/services/theming';

import {setupComponents} from '@odin/components-vue3';
import '@odin/components-vue3/dist/style.css';
import {setupI18n} from '@odin/services';

const config = getConfig();

setupSentry({
  dsn: 'https://2a2c82350f9b4b8481f0bf21d4ae6eca@sentry.websupport.sk/32',
  logErrors: true,
  attachStacktrace: true,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.01, // 1% of sessions
  replaysOnErrorSampleRate: 1.0, // 100% if an error occurs
  integrations: [new Sentry.Replay()],
});

// Check if the user is not already logged in a webmail instance
const isRedirected = checkRedirect();

if (!isRedirected) {

  const initAndMountApp = async () => {
    const i18nInit = init();
    const config = getConfig();
    const i18next = await setupI18n({
      language: config.defaultLang,
      defaultNS: 'app',
    });
    // @ts-ignore
    await setupComponents({i18next});
    await i18nInit;
    app.mount('#ws-webmail-login');
  };

  // @ts-ignore
  const app = createApp(App);
  app.use(i18n);
  app.use(Quasar, { iconSet });

  setupWsWrapper(app);
  setTheme(config.theme);

  initAndMountApp();
}
